<template>
  <moe-page title="商品管理">
    <!-- 筛选信息1 -->
    <moe-inquire @search="journalSearch">
      <el-form-item label="商品编号">
        <el-input :value="goodsRecycleParams.id" @input="(value) => goodsRecycleParams.id = value.replace(/^\.+|[^\d]/g,'')" placeholder="输入商品编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input v-model.trim="goodsRecycleParams.name" placeholder="请输入商品名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([goodsRecycleParams.startTime, goodsRecycleParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 商品信息 -->
    <moe-table ref="goodsRecycleTable" url="/shop/goods/queryShopGoodsRecycle" :params="goodsRecycleParams" :number-show="false" row-key="id">
      <el-table-column prop="id" label="商品编号" min-width="130" />
      <el-table-column prop="coverUrl" label="商品图片" width="130">
        <template slot-scope="{ row }">
          <moe-image :src="row.coverUrl" width="80px" height="80px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" :show-overflow-tooltip="true" label="商品名称" min-width="130" />
      <el-table-column prop="createTime" label="销售价（￥）" min-width="80">
        <template slot-scope="{ row }">
          {{ row.minPrice }}-{{ row.maxPrice }}
        </template>
      </el-table-column>
      <el-table-column prop="buyNum" label="销量" min-width="130" />
      <el-table-column prop="stock" label="库存" min-width="130" />
      <el-table-column prop="createTime" label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.createTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="primary" size="mini" icon="el-icon-refresh-left" @click="shopGoodsReduction(row.id)">恢复到仓库</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'goodsRecycle',
  data() {
    //商品筛选信息
    let goodsRecycleParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      id: '',
      name: '',
      status: '',
      startTime: '',
      endTime: '',
    };

    return {
      goodsRecycleParams, //日志筛选信息
      dateTime: [],
    };
  },
  methods: {
    /**
     * 商品搜索
     **/
    journalSearch(isSearch) {
      if (!isSearch) {
        this.goodsRecycleParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
        };

        this.dateTime = [];
      }

      //刷新商品表格
      this.$refs.goodsRecycleTable.searchData();
    },
    /**
     * 商品还原
     **/
    shopGoodsReduction(id) {
      this.$moe_layer.confirm('是否要恢复该商品到仓库 ?', () => {
        // 调用删除账号API
        this.$moe_api.GOODS_API.shopGoodsReduction({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('成功恢复，请到商品列表中查看');
            //刷新表格
            this.$refs.goodsRecycleTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    }
  },
};
</script>